.job_posting_form_check_box {
  display: none;
}

.job_posting_form_radio_button {
  display: none;
}

// Container for the grid of cards
.job_posting_cards_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 251px);
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  justify-content: center;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, 251px);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, 251px);
  }

  @media (max-width: 576px) {
    grid-template-columns: minmax(251px, 1fr);
  }
}

.job_posting_image_check_box_container {
  border: 2px solid $gray-100;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 10px;
  cursor: pointer;

  &.active_check_box,
  &.active_radio_buttton {
    border: 4px solid $primary;
    padding: 8px !important;
  }
}

.job_posting_check_box_container,
.job_posting_radio_button_container {
  width: 251px;
  min-width: 0;
  border: 2px solid $gray-200;
  border-radius: 10px;
  padding: 1.5rem 1rem;
  cursor: pointer;
  position: relative;
  background: white;
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  // Add checkmark icon that's hidden by default
  &::after {
    content: '✓';
    position: absolute;
    top: 0.3rem;
    right: 0.5rem;
    color: $danger;
    font-size: 1.1rem;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &.active_check_box,
  &.active_radio_buttton {
    border-color: $danger;
    border-width: 2px;
    
    &::after {
      opacity: 1;
    }
  }

  &.active_radio_buttton,
  &.active_check_box {
    background-color: rgba($primary, 0.01);
  }

  // Horizontal specific styles
  &.horizontal {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1rem;

    .package-icon {
      margin-bottom: 0;
    }

    h1 {
      font-size: 1.25rem;
      font-weight: 600;
      color: $gray-900;
    }

    .starting-at-text {
      font-size: 0.75rem;
      color: $gray-600;
      margin: 0;
    }

    .price-label {
      font-size: 2rem;
      font-weight: 800;
      color: $gray-900;
      margin: 0;
      line-height: 1;
      text-align: center;
      width: 100%;
    }

    .price-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.25rem;
    }

    .package-description {
      font-size: 0.875rem;
      color: $gray-700;
      margin-bottom: 0;
    }

    > p {
      color: $gray-600;
      margin-top: 0.5rem;
    }

    > small {
      color: $primary;
      margin-top: 0.5rem;
    }
  }

  // Vertical specific
  .package-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    color: $gray-900;
    margin-bottom: 1rem;
  }

  .package-description {
    color: $gray-700;
    font-size: 0.875rem;
    line-height: 1.5;
    flex-grow: 1;
    margin-bottom: 1rem;
  }

  .price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
  }

  .starting-at-text {
    font-size: 0.875rem;
    color: $gray-600;
    margin: 0;
  }

  .price-label {
    font-size: 2rem;
    font-weight: bold;
    color: $gray-900;
    margin: 0;
    line-height: 1;
  }

  // What's included tooltip styling
  .what-included-tooltip {
    color: $tuw-black;
    text-decoration: underline;
    cursor: help;
    font-size: 0.875rem;
    margin-top: auto;
    padding-top: 1rem;
  }

  // Additional description styling
  p {
    color: $gray-600;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  hr {
    width: 100%;
    margin: 1.5rem 0;
    border-color: $gray-200;
  }

  blockquote {
    font-style: italic;
    color: $gray-700;
    margin: 0;
    padding: 0.5rem 1rem;
    border-left: 3px solid $gray-300;
    font-size: 0.875rem;
  }
}

#job_posting_container {
   min-height: 300px;
}

.job_posting_buttons {
  display: flex;
  max-width: 700px;
  padding: 0 30px;
  margin: 0 auto;
}

#job_posting_form_container {
  opacity: 0;
  transition: opacity 1s;
  padding: 60px 30px 20px;
  width: 100%;
  margin: 0 auto;

  .form-row {
    display: block;
  }

  .form-group {
    display: flex;
    flex-direction: column;
  }
}

.job_posting_index_buttons {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
  justify-content: center;
}

.job_posting_summary_container {
  margin: 0 0 40px;
}

.job_posting_form_with_images_container{
  max-width: 100% !important;
  padding: 0px !important;
}

.art-style{
  width: 600px;
  p {
    font-size: 11px;
  }
  .slider-container {
    width: 100%;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: $gray-200;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 20px;
    background: $primary;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: $primary;
    cursor: pointer;
  }
}


.zoomable {
  position: relative;
}

.zoomable:hover {
  transform: scale(2.5);
}

.sticky-pricing-display {
  position: sticky;
  top: 70px;
}

@media (min-width: 992px) {
  .sticky-pricing-display {
    top: 80px;
  }
}

// Override Bootstrap container width for package selection
.card-body {
  .job_posting_cards_container {
    margin: -1.25rem;
    width: calc(100% + 2.5rem);
  }
}