pre {
  font-family: $font-family-base !important;
  font-size: 100% !important;
}

.pagination {
  .page-item {
    .page-link {
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: $input-height-sm;
      width: $input-height-sm;
      border-radius: 0.42rem;

      outline: none !important;
      vertical-align: middle;

      // Transition
      @include button-custom-transition($transition-link);
    }
  }
}

.hover-text-highlight:hover {
  color: white;
}

.btn-hover-primary:hover {
  .hover-text-highlight {
    color: white;
  }
}
