// Testimonial card
.testimonial-card {
  .card-up {
    overflow: hidden;
    height: $card-up-size;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
  }
  .avatar {
    border-radius: $border-radius-circle;
    width: $card-up-size;
    margin-top: $card-avatar-margin-top;
    overflow: hidden;
    border: 5px solid $white;
  }
  .avatar img {
    width: 100%;
  }
  .card-body {
    text-align: center;
  }
}
