.label {
	padding: 0;
	margin: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	font-size: 0.8rem;
	background-color: $gray-200;
	color: $dark-75;
	font-weight: 400;

	// Theme colors
	@each $name, $color in $order-colors {
		// Solid style
    &.label-#{$name} {
      color: order-inverse-color($name);
			background-color: $color;
    }

		// Outline style
    &.label-outline-#{$name} {
      background-color: transparent;
			color: order-outline-inverse-color($name);
			border: 1px solid $color;

			&.label-outline-2x {
				border: 2px solid $color;
			}
    }

		// Light style
    &.label-light-#{$name} {
      color: $color;
    	background-color: order-light-color($name);
    }
	}
	// Orders Due in colors
	@each $name, $color in $due-in-colors {

		&.label-light-#{$name} {
			color: $color;
			background-color: due-in-light-color("#{$name}-light");
		}
	}
}