//
// SweetAlert2
//

// Base
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
	overflow-y: initial !important;

	&:not(.sweetalert2-nopadding) {
		padding-right: 0 !important;
	}
}

// Popup
.swal2-popup {
	padding: 2rem;
	@include border-radius($border-radius);

	.swal2-icon {
		margin: 2rem 0 0rem 0;
	}

	.swal2-title {
		font-weight: 500;
		font-size: 1.3rem;
		margin: 2rem 0 0 0;
		color: $dark;
	}

	.swal2-content {
		font-weight: normal;
		font-size: 1.1rem;
		margin-top: 1.5rem;
		color: $dark-75;
	}

	.btn {
		margin: 15px 5px 0;
	}

	.swal2-styled {
		&:focus {
			box-shadow: none;
		}
	}

	.swal2-actions {
		margin: 1.5rem auto 1rem auto;
	}
}

// Container
.swal2-container {
	overflow-y: hidden !important;

	&.swal2-shown {
		background-color: rgba(0, 0, 0, 0.2);
	}

	// Content
	.swal2-html-container {
		max-height: 200px;
		overflow: auto;
	}
}

body.swal2-height-auto {
	height: 100% !important;
}

// State Colors
.swal2-icon {
	// Warning
	&.swal2-warning {
    	border-color: $warning;
    	color: $warning;
	}

	// Error
	&.swal2-error {
    	border-color: $danger;
    	color: $danger;

		[class^=swal2-x-mark-line] {
			background-color: rgba($danger, 0.75);
		}
	}

	// Success
	&.swal2-success {
    	border-color: $success;
    	color: $success;

		[class^=swal2-success-line] {
			background-color: $success;
		}

		.swal2-success-ring {
			border-color: rgba($success, 0.3);
		}
	}

	// Info
	&.swal2-info {
    	border-color: $info;
    	color: $info;
	}

	// Question
	&.swal2-question {
    	border-color: $primary;
    	color: $primary;
	}
}
