.msg-container {
  padding: 10px;
  position: relative;

  &:hover {
    background: #fafafa;

    .hide-msg-btn {
      opacity: 1;
    }
  }
}

.hide-msg-btn-container {
  position: absolute;
  right: 10px;
  top: -20px;
  display: none;
}

.hide-incoming-msg-btn-container {
  position: relative;
  right: 10px;
  top: -20px;
  display: none;
}

.hide-outgoing-msg-btn-container {
  position: sticky;
  left: 92%;
  top: -20px;
  display: none;
}

.hide-msg-btn {
  cursor: pointer;
  background: white;
  border-radius: 5px;
  padding: 5px 15px;
  box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.2);
  border: 1px solid #f2f2f2;
  color: grey;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 0;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;

  &:hover {
    color: black;
  }
}
